var generic;

generic = generic || {};

(function ($) {
  Drupal.behaviors.emailSignupBlockSplashPageMEV1 = {
    templateContainer: $('.js-email-signup-block-splash-page-me-v1'),
    initEmailSignupBlock: function (context) {
      var self = this;
      var $emailContainer = self.templateContainer.find('.js-personal-info-form-container', context);
      var $emailFormNode;
      // Mobile Phone Validation Variables
      var $phoneNumberInput = $('.js-form-item-form-PHONE-1');
      var $phonNumberError = $('.js-invalid-phone-email_signup_bb_me');
      var $countryInput = $('.js-form-item-form-COUNTRY');
      var $countryError = $('.js-required-country-email_signup_bb_me');
      var $submitButton = $('.js-form-submit');
      var $pattern;

      if (!$emailContainer) {
        return null;
      }

      // Adding error labels to an indicated object and disabling the input field
      function addPhoneErrors(error, disable) {
        $(error).removeClass('hidden').addClass('label-error');
        if (disable) {
          $(disable).attr('disabled', 'disabled');
        }
      }

      // Removing error labels to an indicated object and enabling the input field
      function clearPhoneErrors(error, disable) {
        $(error).removeClass('label-error').addClass('hidden');
        if (disable) {
          $(disable).removeAttr('disabled');
        }
      }

      // Checking phone number with pattern
      function checkPhoneNumber(pattern, number) {
        if (pattern.test(number) === false) {
          addPhoneErrors($phonNumberError, $submitButton);
        } else {
          clearPhoneErrors($phonNumberError, $submitButton);
        }
      }

      // Clearing phone number field after changing a country
      $countryInput.change(function () {
        $phoneNumberInput.val('');
        clearPhoneErrors($countryError, $phoneNumberInput);
        clearPhoneErrors($phonNumberError, $submitButton);
      });

      // Adding proper prefix to phone number based on country
      $phoneNumberInput.on('focusin', function () {
        if (!$.trim($phoneNumberInput.val())) {
          if ($countryInput.find('option:selected').data('country-id') === 212) {
            $phoneNumberInput.mask('+971000000000');
            $phoneNumberInput.val('+971');
          } else if ($countryInput.find('option:selected').data('country-id') === 191) {
            $phoneNumberInput.mask('+966000000000');
            $phoneNumberInput.val('+966');
          } else if ($countryInput.find('option:selected').data('country-id') === 157) {
            $phoneNumberInput.mask('+96500000000');
            $phoneNumberInput.val('+965');
          } else if ($countryInput.find('option:selected').data('country-id') === 184) {
            $phoneNumberInput.mask('+97400000000');
            $phoneNumberInput.val('+974');
          } else {
            addPhoneErrors($countryError, $phoneNumberInput);
          }
        }
      });

      // Validating phone number after leaving the input field
      $phoneNumberInput.on('focusout', function () {
        if ($.trim($phoneNumberInput.val().replace(/_/g, '')).length <= 4) {
          $phoneNumberInput.val('');
          clearPhoneErrors($phonNumberError, $submitButton);
        } else if ($countryInput.find('option:selected').data('country-id') === 212) {
          $pattern = /^\+[9][7][1]\d{9}$/u;
          checkPhoneNumber($pattern, $phoneNumberInput.val());
          $phoneNumberInput.mask('+971000000000');
        } else if ($countryInput.find('option:selected').data('country-id') === 191) {
          $pattern = /^\+[9][6][6]\d{9}$/u;
          checkPhoneNumber($pattern, $phoneNumberInput.val());
          $phoneNumberInput.mask('+966000000000');
        } else if ($countryInput.find('option:selected').data('country-id') === 157) {
          $pattern = /^\+[9][6][5]\d{8}$/u;
          checkPhoneNumber($pattern, $phoneNumberInput.val());
          $phoneNumberInput.mask('+96500000000');
        } else if ($countryInput.find('option:selected').data('country-id') === 184) {
          $pattern = /^\+[9][7][4]\d{8}$/u;
          checkPhoneNumber($pattern, $phoneNumberInput.val());
          $phoneNumberInput.mask('+97400000000');
        }
      });

      $emailFormNode = $emailContainer.find('form');

      $emailFormNode.once('email_signup').on('submit', function (submitEvt) {
        var fields = [
          '_SUBMIT',
          'ACTION',
          'FIRST_NAME',
          'LAST_NAME',
          'EMAIL_ADDRESS',
          'EMAIL_ADDRESS2',
          'COUNTRY',
          'PHONE1',
          'BIRTH_DATE',
          'EMAIL_PROMOTIONS',
          'SMS_PROMOTIONS',
          'ACCEPTED_PRIVACY_POLICY',
          'SMS_PROMO_ON',
          'LANGUAGE_PREFERENCE',
          'LAST_SOURCE',
          'suppress_conf_email'
        ];
        var paramObj = {};

        submitEvt.preventDefault();
        $emailContainer.find('.form-error div').addClass('hidden');
        $emailContainer.find('.form-item').removeClass('label-error');

        // Loop through all the fields and get the values
        $.each(fields, function (index, value) {
          var $formField = $emailContainer.find('input[name=' + value + ']');

          if (value === 'COUNTRY') {
            $formField = $emailContainer.find('select[name=' + value + ']');
            paramObj['COUNTRY_ID'] = $formField.find('option:selected').data('country-id');
          }

          // For the unchecked checkboxes we want to send empty strings to backend for processing
          if ($formField.is(':checkbox') && !$formField.prop('checked')) {
            paramObj[value] = '0';
          } else {
            paramObj[value] = $formField.val();
          }
        });
        if ($.cookie('csrftoken')) {
          paramObj['_TOKEN'] = $.cookie('csrftoken');
        }

        // Send the data via a json rpc call
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function () {
            window.location.href = $emailContainer.find('input[name=THANK_YOU_PAGE]').val();
          },
          onFailure: function (jsonRpcResponse) {
            var messages = jsonRpcResponse.getMessages();
            var key;
            var field;
            var fieldClass;

            $.each(messages, function (index, message) {
              key = message.key.replaceAll('.', '-');
              key = '.js-' + key;
              field = message.tags[2];
              fieldClass = field.replace('field', '');

              $emailContainer.find(key).removeClass('hidden');
              $emailContainer.find('.form-item' + fieldClass).addClass('label-error');
            });
          }
        });
      });
    },
    attach: function (context) {
      var self = this;

      self.initEmailSignupBlock(context);
    }
  };
})(jQuery);
